import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import _, { values } from 'lodash';
import { ImageViewer, Input, Button, Toast, Skeleton, DotLoading, FloatingPanel, List, CenterPopup, SpinLoading, Popup, Image, Avatar, Switch, Tag, Space, Card } from 'antd-mobile';
import { LoadingOutlined, UnorderedListOutlined, LinkOutlined, PhoneOutlined, CloseOutlined, CheckOutlined, ClockCircleOutlined, PlusOutlined, SendOutlined, SnippetsOutlined } from '@ant-design/icons';
import { IoCheckmarkDone, IoSend, IoImagesOutline, IoVideocamOutline, IoDocumentTextOutline, IoTrashBin, IoTrashBinOutline, IoCloseOutline } from "react-icons/io5";
import { Row, Col, Select, Upload } from 'antd';
import { Spin } from "antd";
import { MdOutlineReply } from "react-icons/md";
import { useLocation } from 'react-router-dom';
import '../style/customeChatBox.css';
import textIcon from '../assets/attachmenticons/text.png';
import fileIcon from '../assets/attachmenticons/file.png';
import excelIcon from '../assets/attachmenticons/excel.png';
import pdfIcon from '../assets/attachmenticons/pdf.png';
import pptIcon from '../assets/attachmenticons/ppt.png';
import wordIcon from '../assets/attachmenticons/word.png';
import templateIconFilled from '../template.svg';
import templateIcon from '../templateFilled.svg';
import { SendOutline, TagOutline } from 'antd-mobile-icons';
import { useNavigate } from 'react-router-dom';
import { MoreOutline, StopOutline } from 'antd-mobile-icons';

const truncateNotes = (notes) => notes.length > 17 ? notes.substring(0, 17) + '...' : notes;

const MAX_FILES = 10;
const MAX_FILE_SIZE_BYTES = 5 * 1024 * 1024; // 5 MB
const MAX_VIDEO_SIZE_BYTES = 16 * 1024 * 1024; // 16 MB
const MAX_DOCUMENT_SIZE_BYTES = 100 * 1024 * 1024; // 100 MB

const SkeletonItem = ({ type }) => (
    <div className={`message ${type}`}>
        <div className='mainboddiv'>
            <Skeleton
                paragraph={{ rows: 1 }}
                style={{
                    width: 100,
                    height: 20,
                    marginBottom: 10,
                }}
            />
            <Skeleton
                paragraph={{ rows: 1 }}
                style={{
                    width: 80,
                    height: 20,
                }}
            />
        </div>
    </div>
);

const ChatBox = ({ userData, setPageTitle, setRightPanel }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const customerData = location.state?.customerData;
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [panelOpen, setPanelOpen] = useState(false);
    const [floatListData, setFloatListData] = useState(null);
    const [visibleViewer, setVisibleViewer] = useState(false)
    const [perviewImage, setPerviewImage] = useState('');
    const [visibleVideoViewer, setVisibleVideoViewer] = useState(false)
    const [perviewVideo, setPerviewVideo] = useState('');
    const [textAreaDisabled, setTextAreaDisabled] = useState(true);
    const chatRef = useRef(null);
    const chatContainerRef = useRef(null);
    const [loadSentMessage, setLoadSentMessage] = useState(false);
    const [visiblePopup, setVisiblePopup] = useState(false);
    const [templatedata, setTemplatedata] = useState([]);
    const [visibleTemplatePopup, setVisibleTemplatePopup] = useState(false);
    const [previewTemplate, setPreviewTemplate] = useState(false);
    const [activeTemplateItem, setActiveTemplateItem] = useState([]);
    const [templatePreview, setTemplatePreview] = useState([]);
    const [selectedValues, setSelectedValues] = useState({});
    const [templateImage, setTemplateImage] = useState('');
    const [templateImageId, setTemplateImageId] = useState('');
    const [templateImageExtension, setTemplateImageExtension] = useState('');
    const [fileList, setFileList] = useState([]);
    const [sideBarVisible, setSideBarVisible] = useState(false);
    const [tagsData, setTagsData] = useState([]);
    const [noteViewLoader, setNoteViewLoader] = useState(false);
    const [notes, setNotes] = useState([]);

    const handleChange = (info) => {
        console.log(info);
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        console.log(info.file.status);
        if (info.file.status === 'done') {
            getBase64One(info.file.originFileObj, (url) => {
                setTemplateImage(url);
                setLoading(false);
            });
        }
    };

    const [assignedTagsData, setAssignedTagsData] = useState([]);

    const getSelectedTags = async (tags) => {
        if (tags !== '') {
            fetch('https://connectby.io:3001/api/get-selected-tags', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tag_ids: tags })
            })
                .then(response => {
                    console.log(response);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setAssignedTagsData(data);
                })
                .catch(error => {
                    console.error('Error fetching notes:', error);
                });
        } else {
            setAssignedTagsData([]);
        }
    };

    const handleUserDetail = () => {
        setNoteViewLoader(true);
        getSelectedTags(customerData.tags);
        const userId = customerData.customer_id;
        fetch('https://connectby.io:3001/api/get-notes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setNotes(data);
            })
            .catch(error => {
                console.error('Error fetching notes:', error);
                // Handle error
            }).finally(() => {
                setNoteViewLoader(false);
            });
    };

    const getTags = async () => {
        const userId = userData?.user_id;
        fetch('https://connectby.io:3001/api/get-tags', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setTagsData(data);
            })
            .catch(error => {
                console.error('Error fetching notes:', error);
            });
    };

    const getBase64One = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const getMimeType = (fileName) => {
        const extension = fileName.split('.').pop();
        switch (extension) {
            case 'ogg':
                return 'audio/ogg';
            case 'mp3':
                return 'audio/mpeg';
            case 'wav':
                return 'audio/wav';
            default:
                return 'audio/mpeg';
        }
    };


    const checkFile = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            Toast.show('You can only upload JPEG/PNG file!');
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            Toast.show('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt5M;
    };


    const customRequestImage = () => {
        return async ({ action, data, file, filename, headers, onError, onProgress, onSuccess }) => {
            try {
                const formData = new FormData();
                formData.append('file', file);
                const fileType = file.type;
                console.log(fileType);
                const response = await fetch(action, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        ...headers,
                        'whatsappaccesstoken': userData?.whatsappAccessToken,
                        'phonenumberid': userData?.phonenumberid
                    },
                });
                const responseData = await response.json();
                setTemplateImageId(responseData.id);
                setTemplateImageExtension(fileType);
                onSuccess(responseData, file);
            } catch (error) {
                onError(error);
                console.log(error);
            }
        };
    };
    const formatText = (text) => {
        // Replace ** with <b> for bold
        text = text.replace(/\*(.*?)\*/g, '<b>$1</b>');

        // Replace _ _ with <i> for italic
        text = text.replace(/_(.*?)_/g, '<i>$1</i>');

        // Replace \n with <br> for new line
        text = text.replace(/\\n/g, '<br>');

        return text;
    };
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            if (file instanceof Blob) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            } else {
                reject(new Error('Invalid file type. Expected a Blob.'));
            }
        });
    const handlePreview = async (file) => {
        console.log(file.type);
        if (!file.url && !file.preview) {
            try {
                const isImage = file.type.startsWith('image/');
                const isVideo = file.type.startsWith('video/');
                const isText = file.type === 'text/plain';
                const isPDF = file.type === 'application/pdf';
                const isPPT = file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
                const isWord = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                const isExcel = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                if (isImage) {
                    // Generate a preview URL using FileReader
                    const preview = await getBase64(file);
                    setPreviewImage(preview);
                    // setUploadFileList((prevImageList) => [
                    //     ...prevImageList,
                    //     { uid: file.uid, url: preview },
                    // ]);
                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: preview },
                    ]);
                } else if (isVideo) {
                    const preview = await getBase64(file);
                    setPreviewImage(preview);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: preview, filetype: 'image' },
                    ]);
                } else if (isText) {
                    setPreviewImage(textIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: textIcon, filetype: 'icon' },
                    ]);
                } else if (isPDF) {
                    setPreviewImage(pdfIcon);
                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: pdfIcon, filetype: 'icon' },
                    ]);
                } else if (isPPT) {
                    setPreviewImage(pptIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: pptIcon, filetype: 'icon' },
                    ]);
                } else if (isWord) {
                    setPreviewImage(wordIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: wordIcon, filetype: 'icon' },
                    ]);
                } else if (isExcel) {
                    setPreviewImage(excelIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: excelIcon, filetype: 'icon' },
                    ]);
                } else {
                    setPreviewImage(fileIcon);
                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: fileIcon, filetype: 'icon' },
                    ]);
                }
                setActiveImageUid(file.uid);
            } catch (error) {
                console.error('Error getting base64:', error);
                setPreviewImage('');
            }
        }

        setPreviewVisible(true);
    };
    const [imageList, setImageList] = useState([]);
    const [activeImageUid, setActiveImageUid] = useState(null);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [imageUploadLoading, setImageUploadLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const customRequest = ({ file, onSuccess, onError, onProgress }) => {
        setFilesToUpload(prevFiles => [...prevFiles, file]);
        if (!previewVisible) {
            setPreviewVisible(true);
        }
        setImageUploadLoading(true);
        const maxRetries = 3;
        let retryCount = 0;
        const uploadWithRetry = () => {
            setTimeout(() => {
                const isUploaded = Math.random() > 0.5;

                if (isUploaded) {
                    onSuccess();
                    handlePreview(file);
                    setImageUploadLoading(false);
                } else {
                    retryCount++;
                    if (retryCount <= maxRetries) {
                        uploadWithRetry();
                    } else {
                        onError();
                        Toast.show(`${file.name} file upload failed after ${maxRetries} retries`);
                        setImageUploadLoading(false);
                    }
                }
            }, 1000);
        };

        uploadWithRetry();
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const TemplateItem = ({ item }) => {
        return (
            <List.Item
                key={item.id}
                className={`templateList`}
                prefix={''}
                clickable
                onClick={() => { setActiveTemplateItem(item); setPreviewTemplate(true) }}
                description={<div style={{ display: 'flex', justifyContent: 'space-between' }}><span><b>Status:</b> {item.status}</span><span><b>Language:</b> {item.language}</span></div>}
            >
                {truncateNotes(item.name.charAt(0).toUpperCase() + item.name.slice(1).replace(/_/g, ' ') || 'Unknown')}
            </List.Item>
        );
    };

    useEffect(() => {
        if (!customerData) return;
        const pageTitle = (
            <div style={{ display: 'flex', height: '45px', justifyContent: 'flex-start', alignItems: 'center' }}>
                <div className="customer-avatar" style={{ marginTop: 3 }}>
                    {customerData.customer_name ? customerData.customer_name[0].toUpperCase() : ''}
                </div>
                <div style={{ marginLeft: 10, textAlign: 'left' }}>
                    <h4 style={{ lineHeight: 0, marginBottom: 5, marginTop: 15 }}>
                        {customerData.customer_name?.length > 10
                            ? `${customerData.customer_name.slice(0, 10)}...`
                            : customerData.customer_name}
                    </h4>
                    <a style={{ fontSize: '14px' }} href={`tel:+${customerData.country_code}${customerData.customer_phone}`} target="_blank">
                        {`+ ${customerData.country_code} ${customerData.customer_phone}`}
                    </a>
                </div>
            </div>
        );
        setPageTitle(pageTitle);
        setRightPanel(<MoreOutline onClick={() => setSideBarVisible(true)} style={{ fontSize: '30px', rotate: '90deg' }} />)
        handleUserDetail();
    }, [customerData, setPageTitle, setRightPanel, sideBarVisible]);

    function formatPhoneId(countryCode, phoneNumber) {
        if (countryCode.startsWith('+')) {
            countryCode = countryCode.substring(1);
        }
        return countryCode + phoneNumber;
    }

    useEffect(() => {
        function newchat() {
            let ws = new WebSocket('wss://connectby.io:3001');
            ws.onopen = () => {
                console.log('WebSocket connected');
            };

            ws.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    if (data?.is_message === 'to') {
                        const tomessagesNumber = data?.wanumber;
                        let toactivePhone = '';
                        if (customerData) {
                            toactivePhone = formatPhoneId(customerData.country_code, customerData.customer_phone);
                        } else {
                            toactivePhone = "notactive";
                        }
                        if (tomessagesNumber === toactivePhone) {
                            const tonewReceivedMessage = {
                                wamid: data?.wamid,
                                mainid: data?.mainid,
                                is_message: data?.is_message,
                                wanumber: data?.wanumber,
                                msgtype: data?.msgtype,
                                message: data?.message,
                                callback: data?.callback,
                                created: data?.created,
                                status: 'read',
                            };
                            setMessages(prevChat => {
                                const widExists = prevChat.some(message => message.mainid === tonewReceivedMessage.mainid);
                                if (widExists) {
                                    return prevChat;
                                }
                                return [...prevChat, tonewReceivedMessage];
                            });
                        }
                    }
                    if (data?.user_data?.customer_id === userData?.user_id) {
                        if (data.entry && data.entry.length > 0 && data.entry[0].changes) {
                            const messagesChange = data.entry[0].changes.find(change => change.field === 'messages');
                            if (messagesChange && messagesChange.value.messages) {
                                const receivedMessage = messagesChange.value.messages[0];
                                const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;
                                const messagesName = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.profile?.name;
                                let activephone = '';
                                if (customerData) {
                                    activephone = formatPhoneId(customerData.country_code, customerData.customer_phone);
                                } else {
                                    activephone = "notactive";
                                }
                                if (receivedMessage.type === 'text') {
                                    const newReceivedMessage = {
                                        wamid: data.entry[0].id,
                                        mainid: receivedMessage.id,
                                        is_message: 'from',
                                        wanumber: messagesNumber,
                                        msgtype: receivedMessage.type,
                                        message: receivedMessage?.text?.body,
                                        callback: JSON.stringify(data),
                                        created: Math.floor(Date.now() / 1000).toString(),
                                        status: 'received',
                                    };
                                    if (activephone !== 'notactive') {
                                        if (activephone === messagesNumber) {
                                            console.log('active');
                                            setMessages(prevChat => {
                                                const widExists = prevChat.some(message => message.mainid === newReceivedMessage.mainid);
                                                if (widExists) {
                                                    return prevChat;
                                                }
                                                return [...prevChat, newReceivedMessage];
                                            });
                                            setTextAreaDisabled(false);
                                            updateReceivedMessage(newReceivedMessage);
                                        }
                                    }
                                }
                                if (receivedMessage.type === 'image') {
                                    const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;
                                    const newReceivedMessage = {
                                        wamid: data.entry[0].id,
                                        mainid: receivedMessage.id,
                                        is_message: 'from',
                                        wanumber: messagesNumber,
                                        msgtype: receivedMessage.type,
                                        message: '',
                                        image: {
                                            mime_type: receivedMessage.image.mime_type,
                                            sha256: receivedMessage.image.sha256,
                                            id: receivedMessage.image.id,
                                        },
                                        callback: JSON.stringify(data),
                                        created: Math.floor(Date.now() / 1000).toString(),
                                        status: 'received',
                                    };
                                    if (activephone !== 'notactive') {
                                        if (activephone === messagesNumber) {
                                            renderReceivedImage(receivedMessage.id, receivedMessage.image.id);
                                            setMessages(prevChat => {
                                                const widExists = prevChat.some(message => message.wid === newReceivedMessage.mainid);
                                                if (widExists) {
                                                    return prevChat;
                                                }
                                                return [...prevChat, newReceivedMessage];
                                            });
                                            updateReceivedMessage(newReceivedMessage);
                                        }
                                    }
                                }
                                if (receivedMessage.type === 'video') {
                                    const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;
                                    const newReceivedMessage = {
                                        wamid: data.entry[0].id,
                                        mainid: receivedMessage.id,
                                        is_message: 'from',
                                        wanumber: messagesNumber,
                                        msgtype: receivedMessage.type,
                                        message: '',
                                        video: {
                                            mime_type: receivedMessage.video.mime_type,
                                            sha256: receivedMessage.video.sha256,
                                            id: receivedMessage.video.id,
                                        },
                                        callback: JSON.stringify(data),
                                        created: Math.floor(Date.now() / 1000).toString(),
                                        status: 'received',
                                    };
                                    if (activephone !== 'notactive') {
                                        if (activephone === messagesNumber) {
                                            renderReceivedVideo(receivedMessage.id, receivedMessage.video.id);
                                            setMessages(prevChat => {
                                                const widExists = prevChat.some(message => message.wid === newReceivedMessage.mainid);
                                                if (widExists) {
                                                    return prevChat;
                                                }
                                                return [...prevChat, newReceivedMessage];
                                            });
                                            updateReceivedMessage(newReceivedMessage);
                                        }
                                    }
                                }
                                if (receivedMessage.type === 'audio') {
                                    const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;
                                    const newReceivedMessage = {
                                        wamid: data.entry[0].id,
                                        mainid: receivedMessage.id,
                                        is_message: 'from',
                                        wanumber: messagesNumber,
                                        msgtype: receivedMessage.type,
                                        message: '',
                                        audio: {
                                            mime_type: receivedMessage.audio.mime_type,
                                            sha256: receivedMessage.audio.sha256,
                                            id: receivedMessage.audio.id,
                                        },
                                        callback: JSON.stringify(data),
                                        created: Math.floor(Date.now() / 1000).toString(),
                                        status: 'received',
                                    };
                                    if (activephone !== 'notactive') {
                                        if (activephone === messagesNumber) {
                                            renderReceivedAudio(receivedMessage.id, receivedMessage.audio.id);
                                            setMessages(prevChat => {
                                                const widExists = prevChat.some(message => message.wid === newReceivedMessage.mainid);
                                                if (widExists) {
                                                    return prevChat;
                                                }
                                                return [...prevChat, newReceivedMessage];
                                            });
                                            updateReceivedMessage(newReceivedMessage);
                                        }
                                    }
                                }
                                if (receivedMessage.type === 'document') {
                                    const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;
                                    const newReceivedMessage = {
                                        wamid: data.entry[0].id,
                                        mainid: receivedMessage.id,
                                        is_message: 'from',
                                        wanumber: messagesNumber,
                                        msgtype: receivedMessage.type,
                                        message: '',
                                        document: {
                                            mime_type: receivedMessage.document.mime_type,
                                            sha256: receivedMessage.document.sha256,
                                            id: receivedMessage.document.id,
                                        },
                                        callback: JSON.stringify(data),
                                        created: Math.floor(Date.now() / 1000).toString(),
                                        status: 'received',
                                    };
                                    if (activephone !== 'notactive') {
                                        if (activephone === messagesNumber) {
                                            renderReceivedDocument(receivedMessage.id, receivedMessage.document.id);
                                            setMessages(prevChat => {
                                                const widExists = prevChat.some(message => message.wid === newReceivedMessage.mainid);
                                                if (widExists) {
                                                    return prevChat;
                                                }
                                                return [...prevChat, newReceivedMessage];
                                            });
                                            updateReceivedMessage(newReceivedMessage);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (data.entry && data.entry.length > 0 && data.entry[0].changes) {
                        const messagesChange = data.entry[0].changes.find(change => change.field === 'messages');
                        if (messagesChange && messagesChange.value.statuses) {
                            const statuses = messagesChange.value.statuses[0];
                            const ststusid = statuses.id;
                            const status = statuses.status;
                            updateStatus(ststusid, status);
                        }
                    }
                } catch (error) {
                    console.log(error);
                    console.log('Received non-JSON data:', event.data);
                }
            };

            ws.onclose = () => {
                console.log('WebSocket closed');
                setTimeout(() => {
                    newchat();
                }, 3000);
            };
        }
        newchat();
    }, []);



    const updateReceivedMessage = async (newReceivedMessage) => {

        try {
            const response = await fetch('https://connectby.io:3001/api/update-received-message-status', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.whatsappAccessToken,
                    'phonenumberid': userData?.phonenumberid
                },
                body: JSON.stringify({
                    message_id: newReceivedMessage.mainid,
                    wamid: newReceivedMessage.wamid,
                    wanumber: newReceivedMessage.wanumber
                }),
            });
            console.log(response);
            if (!response.ok) {
                throw new Error(`Update Status failed with status ${response.status}`);
            }
            const data = await response.json();
            console.log('Update Status API response:', data);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const onVarChange = (element, value) => {
        setSelectedValues(prevValues => ({
            ...prevValues,
            [element]: value,
        }));
    };

    const onVarSearch = (value) => {
        console.log('search:', value);
    };

    const inputfilterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const updateStatus = async (statusId, newStatus) => {
        setMessages((prevChatContainer) => {
            return prevChatContainer.map((chat) => {
                if (chat.mainid === statusId) {
                    if (chat.status !== 'read') {
                        return { ...chat, status: newStatus };
                    }
                }
                return chat;
            });
        });
    };

    const fetchMessages = useCallback(async () => {
        if (!userData) return;
        if (!customerData) return;
        setLoading(true);
        try {
            const response = await fetch(`https://connectby.io:3001/api/getchat?phoneid=${userData?.phonenumberid}&id=${customerData.country_code}${customerData.customer_phone}&page=${pageNumber}`);
            const result = await response.json();
            if (result) {
                if (pageNumber === 1) {
                    if (result) {
                        const newReceivedMessage = {
                            mainid: result[0]?.mainid,
                            wamid: result[0]?.wamid,
                            wanumber: result[0]?.wanumber
                        };
                        updateReceivedMessage(newReceivedMessage);
                    }

                }
                setMessages(prevData => {
                    const existingIds = new Set(prevData.map(item => item.wid));
                    const newData = result.filter(item => !existingIds.has(item.wid));
                    const combinedData = [...prevData, ...newData];
                    combinedData.sort((a, b) => a.wid - b.wid);
                    return combinedData;
                });
                setHasMore(result.length === 10);
            } else {
                Toast.show({ content: 'Failed to fetch messages', duration: 2000 });
            }
        } catch (error) {
            Toast.show({ content: `Error: ${error.message}`, duration: 2000 });
        } finally {
            setLoading(false);
        }
    }, [customerData, userData, pageNumber]);

    useEffect(() => {
        fetchMessages();
    }, [fetchMessages]);

    useEffect(() => {
        if (!customerData) return;
        isMessageOld(customerData)
            .then(isOld => {
                setTextAreaDisabled(isOld);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        getSelectedTags(customerData?.tags);
    }, [customerData, textAreaDisabled]);


    useEffect(() => {
        const templatedataUrl = `https://connectby.io:3001/api/get-template`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'whatsappaccesstoken': userData?.whatsappAccessToken,
                'wabaid': userData?.wabaid
            }
        };
        fetch(templatedataUrl, requestOptions)
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            })
            .then((body) => {
                if (body.success === true) {
                    setTemplatedata(body.responseData.data);
                } else {
                    console.error('Error: Success is not true');
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [userData]);

    const handleSend = async () => {
        if (!newMessage.trim()) return;
        setLoadSentMessage(true);
        let countryCode = customerData.country_code;
        if (countryCode.startsWith('+')) {
            countryCode = countryCode.substring(1);
        }
        const wanumber = countryCode + customerData.customer_phone;
        const textmessage = newMessage;

        try {
            const response = await fetch('https://connectby.io:3001/api/send-message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.whatsappAccessToken,
                    'phonenumberid': userData?.phonenumberid
                },
                body: JSON.stringify({
                    to: wanumber,
                    message: textmessage,
                }),
            });

            const responseData = await response.json();

            if (response.ok) {
                Toast.show({
                    icon: 'success',
                    content: 'Message sent.',
                });
                const sentMessage = {
                    created: Date.now() / 1000,
                    is_message: 'to',
                    mainid: responseData.responseData.messages[0].id,
                    msgtype: 'text',
                    message: textmessage,
                    status: 'sending',
                };
                setMessages(prevChat => [...prevChat, sentMessage]);
                chatRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                setNewMessage('');
            } else {
                Toast.show('Failed to send message: ' + (responseData.error || 'Unknown error'));
            }
        } catch (error) {
            Toast.show('Error sending message: ' + error.message);
        } finally {
            setLoadSentMessage(false);
        }
    };

    useEffect(() => {
        if (pageNumber === 1) {
            chatRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    }, [pageNumber, messages]);

    const handleScroll = () => {
        if (chatContainerRef.current) {
            const { scrollTop } = chatContainerRef.current;
            if (!loading) {
                console.log(loading);
                if (scrollTop < 100 && hasMore) {
                    setPageNumber(prevPage => prevPage + 1);
                }
            }
        }
    };

    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        chatContainer?.addEventListener('scroll', handleScroll);
        return () => {
            chatContainer?.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const openModal = (image) => {
        setPerviewImage(image);
        setVisibleViewer(true);
    };
    const openVideoModal = (video) => {
        setPerviewVideo(video);
        setVisibleVideoViewer(true);
    };
    const anchors = [300, window.innerHeight * 0.4, window.innerHeight * 0.8];
    const handleFloatingPanel = parsedMessage => {
        setFloatListData(parsedMessage);
        setPanelOpen(true);
    };

    const [blockLoading, setBlockLoading] = useState(false);
    const handleBlockStatus = async (customerId, status) => {
        console.log(customerId);
        console.log(status);
        setBlockLoading(true);
        try {
            const response = await fetch('https://connectby.io:3001/api/unblock-customer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customer_id: customerId,
                    status: status
                }),
            });
            if (response.ok) {
                navigate('/contacts');
                console.log(response);
                if (status === '1') {
                    Toast.show('Unblocked');
                } else {
                    Toast.show('Blocked');
                }
            } else {
                const errorMessage = await response.json();
                Toast.show(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error Creating Tag:', error);
        } finally {
            setBlockLoading(false);
        }
    };

    const handleReplaceText = (text) => {
        if (text) {
            text = text.replace(/\*([^*]+)\*/g, "<b>$1</b>");
            text = text.replace(/_([^_]+)_/g, "<i>$1</i>");
            text = text.replace(/\n/g, "<br>");
            text = text.replace(
                /((https?:\/\/)?[^\s]+\.[a-z]{2,}(\.[a-z]{2,})?(\/[^\s]*)?)/gi,
                (url) => {
                    const hrefUrl = url.match(/https?:\/\//) ? url : `http://${url}`;
                    const displayUrl = url.replace(/https?:\/\//, "");
                    return `<a href="${hrefUrl}" target="_blank">${displayUrl}</a>`;
                }
            );
            text = text.replace(
                /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g,
                '<a href="mailto:$1">$1</a>'
            );
            text = text.replace(
                /(\+?\d[\d -]{7,}\d)/g,
                '<a href="tel:$1">$1</a>'
            );
        }
        return text;
    };

    const getExtensionFromMimeType = (mimeType) => {
        const mimeTypeToExtensionMap = {
            'image/jpeg': 'jpeg',
            'image/png': 'png',
            'image/gif': 'gif',
            'video/mp4': 'mp4',
            'video/3gp': '3gp',
            'audio/aac': 'acc',
            'audio/mp3': 'mp3',
            'audio/amr': 'amr',
            'audio/mpeg': 'mpeg',
            'audio/ogg': 'ogg',
            'application/pdf': 'pdf',
            'text/plain': 'txt',
            'application/vnd.ms-powerpoint': 'pptx',
            'application/msword': 'doc',
            'application/vnd.ms-excel': 'xls',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
        };
        return mimeTypeToExtensionMap[mimeType] || 'txt';
    };

    const renderReceivedImage = async (mainid, image) => {
        // setFromImgLoading(true);

        try {
            const response = await fetch(`https://connectby.io:3001/api/download-media/${image}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.whatsappAccessToken,
                    'phonenumberid': userData?.phonenumberid
                }
            });
            const result = await response.json();
            if (response.ok) {
                setMessages((prevChatContainer) => {
                    return prevChatContainer.map((chat) => {
                        if (chat.mainid === mainid) {
                            return { ...chat, image: { url: `https://api.connectby.io/upload/${image}.${getExtensionFromMimeType(result.mediaDetails.mime_type)}` } };
                        }
                        return chat;
                    });
                });
            } else {
                console.error('Failed to download media file:', result.error);
            }
        } catch (error) {
            console.error('Error downloading media:', error);
        } finally {
            // setFromImgLoading(false);
        }
    };

    const renderReceivedAudio = async (mainid, audio) => {
        try {
            const response = await fetch(`https://connectby.io:3001/api/download-media/${audio}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.whatsappAccessToken,
                    'phonenumberid': userData?.phonenumberid
                }
            });
            const result = await response.json();
            if (response.ok) {
                setMessages((prevChatContainer) => {
                    return prevChatContainer.map((chat) => {
                        if (chat.mainid === mainid) {
                            return { ...chat, audio: { url: `https://api.connectby.io/upload/${audio}.${getExtensionFromMimeType(result.mediaDetails.mime_type)}` } };
                        }
                        return chat;
                    });
                });
            } else {
                console.error('Failed to download media file:', result.error);
            }
        } catch (error) {
            console.error('Error downloading media:', error);
        } finally {
            // setFromImgLoading(false);
        }
    };

    const renderReceivedVideo = async (mainid, video) => {
        try {
            const response = await fetch(`https://connectby.io:3001/api/download-media/${video}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.whatsappAccessToken,
                    'phonenumberid': userData?.phonenumberid
                }
            });
            const result = await response.json();
            if (response.ok) {
                setMessages((prevChatContainer) => {
                    return prevChatContainer.map((chat) => {
                        if (chat.mainid === mainid) {
                            return { ...chat, video: { url: `https://api.connectby.io/upload/${video}.${getExtensionFromMimeType(result.mediaDetails.mime_type)}` } };
                        }
                        return chat;
                    });
                });
            } else {
                console.error('Failed to download media file:', result.error);
            }
        } catch (error) {
            console.error('Error downloading media:', error);
        } finally {
            // setFromImgLoading(false);
        }
    };

    const renderReceivedDocument = async (mainid, document) => {
        if (document) {
            try {
                const response = await fetch(`https://connectby.io:3001/api/download-media/${document}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'whatsappaccesstoken': userData?.whatsappAccessToken,
                        'phonenumberid': userData?.phonenumberid
                    }
                });
                const result = await response.json();
                if (response.ok) {
                    setMessages((prevChatContainer) => {
                        return prevChatContainer.map((chat) => {
                            if (chat.mainid === mainid) {
                                return { ...chat, document: { url: `https://api.connectby.io/upload/${document}.${getExtensionFromMimeType(result.mediaDetails.mime_type)}` } };
                            }
                            return chat;
                        });
                    });
                } else {
                    console.error('Failed to download media file:', result.error);
                }
            } catch (error) {
                console.error('Error downloading media:', error);
            } finally {
                // setFromImgLoading(false);
            }
        }
    };

    const getFileExtension = (url) => {
        // Check if the URL is not empty and contains a period (.)
        if (url && url.includes('.')) {
            // Split the URL by '.' and get the last element
            return url.split('.').pop();
        } else {
            // If URL is empty or doesn't contain a period, return null or handle as needed
            return null;
        }
    };

    const [files, setFiles] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(1);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(1);
    const [attachmentLoader, setAttachmentLoader] = useState(false);
    const fileInputRef = useRef(null);
    const viewerRef = useRef(null);

    const handlePhotoUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);

        if (selectedFiles.length + files.length > MAX_FILES) {
            Toast.show(`You can only select up to ${MAX_FILES} files.`);
            return;
        }

        const validFiles = [];
        for (const file of selectedFiles) {
            if (file.size > MAX_FILE_SIZE_BYTES) {
                Toast.show('File size exceeds 5 MB. Please select a smaller file.');
                continue;
            }

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                Toast.show('Please select only JPEG or PNG images.');
                continue;
            }

            validFiles.push(file);
        }

        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
        setShowPreview(true);
        setVisiblePopup(false);
    };

    const handleFileChangeDoc = (event) => {
        const selectedFiles = Array.from(event.target.files);
        if (selectedFiles.length + files.length > MAX_FILES) {
            Toast.show(`You can only select up to ${MAX_FILES} files.`);
            return;
        }

        const validFiles = [];
        for (const file of selectedFiles) {
            if (file.size > MAX_DOCUMENT_SIZE_BYTES) {
                Toast.show('File size exceeds 100 MB. Please select a smaller file.');
                continue;
            }

            if (file.type !== 'text/plain' && file.type !== 'application/pdf' && file.type !== 'application/vnd.ms-powerpoint' && file.type !== 'application/msword' && file.type !== 'application/vnd.ms-excel' && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && file.type !== 'application/vnd.openxmlformats-officedocument.presentationml.presentation' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                Toast.show('Please select only txt, pdf, xlsx, xls, word, wordx, ppt or pptx images.');
                continue;
            }

            validFiles.push(file);
        }

        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
        setShowPreview(true);
        setVisiblePopup(false);
    };
    const [tmpBtnLoading, setTmpBtnLoading] = useState(false);
    const handleFileChangeVideo = (event) => {
        const selectedFiles = Array.from(event.target.files);

        if (selectedFiles.length + files.length > MAX_FILES) {
            Toast.show(`You can only select up to ${MAX_FILES} files.`);
            return;
        }

        const validFiles = [];
        for (const file of selectedFiles) {
            if (file.size > MAX_VIDEO_SIZE_BYTES) {
                Toast.show('File size exceeds 16 MB. Please select a smaller file.');
                continue;
            }

            if (file.type !== 'video/mp4' && file.type !== 'video/3gp') {
                Toast.show('Please select only MP4 or 3GP images.');
                continue;
            }

            validFiles.push(file);
        }

        setVideoFiles((prevFiles) => [...prevFiles, ...validFiles]);
        setShowVideoPopup(true);
        setVisiblePopup(false);
    };

    const handleRemoveFile = (currentIndex, index) => {
        if (currentIndex === index) {
            setFiles((prevFiles) => {
                const updatedFiles = prevFiles.filter((_, i) => i !== index);
                if (updatedFiles.length === 0) {
                    setFiles([]);
                    setShowPreview(false);
                    setCurrentIndex(1);
                    setVisiblePopup(true);
                }
                return updatedFiles;
            });
        } else {
            setCurrentIndex(index);
        }
    };

    useEffect(() => {
        if (showPreview && viewerRef.current) {
            viewerRef.current.swipeTo(currentIndex, true);
        }
    }, [showPreview, currentIndex]);

    function getTimeFromDate(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amPM = hours >= 12 ? 'PM' : 'AM';
        const twelveHourFormat = hours % 12 || 12;

        const hours12 = twelveHourFormat.toString().padStart(2, '0');
        const minutesStr = minutes.toString().padStart(2, '0');

        return `${hours12}:${minutesStr} ${amPM}`;
    }

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);

        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        if (date >= today) {
            if (date >= tomorrow) {
                return date.toLocaleDateString('en-US', { weekday: 'long' });
            } else if (date >= today) {
                return getTimeFromDate(date);
            }
        } else if (date >= yesterday) {
            return 'Yesterday';
        } else if (date >= today - 6 * 24 * 60 * 60 * 1000) {
            return date.toLocaleDateString('en-US', { weekday: 'long' });
        }

        return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
    };



    const handleUpload = async () => {
        const textAreaMessage = '';
        let countryCode = customerData.country_code;
        if (countryCode.startsWith('+')) {
            countryCode = countryCode.substring(1);
        }
        const wanumber = countryCode + customerData.customer_phone;
        const formData = new FormData();
        [...files, ...videoFiles].forEach((file) => formData.append('file', file));
        formData.append('to', wanumber);
        formData.append('message', textAreaMessage);

        try {
            setAttachmentLoader(true);
            const response = await fetch('https://connectby.io:3001/api/send-attachments', {
                method: 'POST',
                headers: {
                    'whatsappaccesstoken': userData?.whatsappAccessToken,
                    'phonenumberid': userData?.phonenumberid
                },
                body: formData
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Response error:', errorData);
                throw new Error('Failed to send attachments');
            }

            const responseDataArray = await response.json();
            responseDataArray.responseDataArray.forEach(responseData => {
                const sentMessage = {
                    created: Date.now() / 1000,
                    is_message: 'to',
                    mainid: responseData.mainid,
                    msgtype: responseData.msgtype,
                    message: responseData.message,
                    status: 'sending',
                };

                setMessages(prevChat => [...prevChat, sentMessage]);
            });

            setFiles([]);
            setShowPreview(false);
            setVideoFiles([]);
            setShowVideoPopup(false);
            console.log('Attachments sent successfully');
        } catch (error) {
            console.error('Error sending attachments:', error);
        } finally {
            setAttachmentLoader(false);
        }
    };

    const getIconForFile = (fileType) => {
        if (fileType.includes('pdf')) return pdfIcon;
        if (fileType.includes('msword') || fileType.includes('wordprocessingml')) return wordIcon;
        if (fileType.includes('vnd.openxmlformats-officedocument.wordprocessingml')) return wordIcon;
        if (fileType.includes('excel')) return excelIcon;
        if (fileType.includes('presentation')) return pptIcon;
        return fileIcon;
    };

    const imageObjects = files.map((file) => (file['type'].includes('image/') ? [URL.createObjectURL(file)] : getIconForFile(file.type)));

    const handleVideoUpload = () => {
        if (videoInputRef.current) {
            videoInputRef.current.click();
        }
    };

    const handleDocumentUpload = () => {
        if (documentInputRef.current) {
            documentInputRef.current.click();
        }
    };

    const HandleTemplatePreview = () => {
        setVisibleTemplatePopup(true);
    };

    const videoInputRef = useRef(null);
    const documentInputRef = useRef(null);
    const [videoFiles, setVideoFiles] = useState([]);
    const [showVideoPopup, setShowVideoPopup] = useState(false);

    const handleCancle = () => {
        setFiles([]);
        setShowPreview(false);
        setCurrentIndex(1);
        setVisiblePopup(true);
    };

    const handleCancleVideoPopup = () => {
        setVideoFiles([]);
        setShowVideoPopup(false);
        setCurrentVideoIndex(1);
        setVisiblePopup(true);
    };

    const handleIndexChange = (index) => {
        setCurrentIndex(index);
    };

    const handleSendTemplate = async () => {
        let countryCode = customerData.country_code;
        if (countryCode.startsWith('+')) {
            countryCode = countryCode.substring(1);
        }
        const template = _.cloneDeep(activeTemplateItem);
        const templateName = template.name;
        const templateLanguage = template.language;
        const wanumber = countryCode + customerData.customer_phone;
        const variables = selectedValues;
        const imageId = templateImageId;
        const imageExtension = templateImageExtension;
        let datacomponent = [];
        const headerComponent = template?.components?.find(component => component.type === "HEADER");
        if (headerComponent) {
            if (headerComponent.format === 'IMAGE') {
                if (imageId === '') {
                    Toast.show('Please Upload the image');
                    return;
                }
            }
        }
        template?.components?.forEach(component => {
            if (component.type === "HEADER") {
                const header = {
                    type: "header",
                    parameters: []
                };

                if (component.format === 'TEXT') {
                    let replacedHeader = component.text;
                    component.text.match(/{{\d+}}/g).forEach(element => {
                        const replacement = variables[`HEADER-${element}`];
                        replacedHeader = replacedHeader.replace(element, replacement);
                        header.parameters.push({
                            type: "text",
                            text: variables[`HEADER-${element}`]
                        });
                    });
                    component.text = replacedHeader;
                }

                if (component.format === 'IMAGE') {
                    if (imageExtension === 'image/png') {
                        component.example.header_handle[0] = `https://api.connectby.io/whatsapp_media/${imageId}.png`;
                    }
                    if (imageExtension === 'image/jpeg') {
                        component.example.header_handle[0] = `https://api.connectby.io/whatsapp_media/${imageId}.jpeg`;
                    }
                    header.parameters.push({
                        type: "image",
                        image: {
                            id: imageId
                        }
                    });
                }

                if (header.parameters.length > 0) {
                    datacomponent.push(header);
                }
            }

            if (component.type === "BODY") {
                const body = {
                    type: "body",
                    parameters: []
                };
                let replacedBody = component.text;
                if (component.text.match(/{{\d+}}/g)) {
                    component.text.match(/{{\d+}}/g).forEach(element => {
                        const replacement = variables[`BODY-${element}`];
                        replacedBody = replacedBody.replace(element, replacement);
                        body.parameters.push({
                            type: "text",
                            text: variables[`BODY-${element}`]
                        });
                    });
                }
                component.text = replacedBody;
                if (body.parameters.length > 0) {
                    datacomponent.push(body);
                }
            }
        });
        try {
            setTmpBtnLoading(true);
            const response = await fetch('https://connectby.io:3001/api/send-whatsapp-template', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.whatsappAccessToken,
                    'phonenumberid': userData?.phonenumberid
                },
                body: JSON.stringify({
                    to: wanumber,
                    template_name: templateName,
                    language: templateLanguage,
                    template: template,
                    components: datacomponent
                }),
            });
            const responseData = await response.json();
            if (response.ok) {
                const sentMessage = {
                    created: Date.now() / 1000,
                    is_message: 'to',
                    mainid: responseData.responseData.messages[0].id,
                    msgtype: 'template',
                    message: templateName,
                    status: 'sending',
                    template: JSON.stringify(template)
                };
                setMessages(prevChat => [...prevChat, sentMessage]);
                Toast.show('Template sent successfully');
                console.log('WhatsApp template sent successfully:', responseData);
            } else {
                Toast.show(responseData.error || 'Unknown error');
                console.error('Failed to send WhatsApp template:', responseData.error || 'Unknown error');
            }
        } catch (error) {
            Toast.show('Internal Server Error');
        } finally {
            setTmpBtnLoading(false);
            setPreviewTemplate(false);
            setActiveTemplateItem([]);
            setTemplatePreview([]);
            setVisibleTemplatePopup(false);
        }
    };

    const renderFooter = (image, index) => (
        <div className='custom-footer'>
            <div className='thumbnailofimagespreview'>
                {imageObjects.map((image, index) => (
                    <div className={currentIndex === index && 'active'} onClick={() => handleRemoveFile(currentIndex, index)}>
                        {currentIndex === index && (
                            <span color='danger' className='removeImageBtn'><IoTrashBinOutline /></span>
                        )}
                        <Image src={image} className='thumbnailimage' />
                    </div>
                ))}
                {imageObjects.length < 10 && (
                    <div className='addMore' onClick={() => handlePhotoUpload(currentIndex, index)}>
                        <PlusOutlined />
                    </div>
                )}
            </div>

            <Button onClick={handleUpload} className='sendBtn' color='primary'>
                {attachmentLoader ? (<SpinLoading />) : (<IoSend />)}
            </Button>
            <Button onClick={handleCancle} color='primary' className='closePreviewBtn'>
                <IoCloseOutline />
            </Button>
        </div>
    );
    const [filterOption, setFilterOption] = useState([]);

    const isMessageOld = async (item) => {
        const getStatus = `https://connectby.io:3001/api/get-last-msg-status?phoneid=${userData?.phonenumberid}&phonenumber=${item.country_code}${item.customer_phone}`;
        try {
            const response = await fetch(getStatus);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            if (data) {
                const messageDate = new Date(data[0].created * 1000);
                const currentDate = new Date();
                const hoursDifference = (currentDate - messageDate) / (1000 * 60 * 60);
                return hoursDifference > 24;
            } else {
                return true;
            }
        } catch (error) {
            return true;
        } finally {

        }
    };

    useEffect(() => {
        if (activeTemplateItem && Array.isArray(activeTemplateItem.components)) {
            setTemplatePreview(activeTemplateItem.components);
            let countryCode = customerData.country_code;
            if (countryCode.startsWith('+')) {
                countryCode = countryCode.substring(1);
            }
            let options = [
                {
                    value: customerData.customer_name,
                    label: 'Name',
                },
                {
                    value: customerData.customer_email,
                    label: 'Email',
                },
                {
                    value: countryCode + customerData.customer_phone,
                    label: 'Phone Number',
                },
            ];
            setFilterOption(options);
        } else {
            console.error('Invalid item format:', activeTemplateItem);
        }
    }, [customerData, activeTemplateItem, previewTemplate]);

    return (
        <div style={{ height: 'calc(100vh - 110px)', top: '0px', position: 'relative', display: 'flex', flexDirection: 'column', paddingBottom: '80px' }} className='chatboxBackground'>
            <div ref={chatContainerRef} style={{ flex: 1, overflowY: 'scroll', padding: '10px' }}>
                {loading && <div className='loading'>
                    {Array.from({ length: 10 }).map((_, index) => (
                        <SkeletonItem key={index} type={index % 2 === 0 ? 'my-message' : 'other-message'} />
                    ))}
                </div>}
                {hasMore && <div className='loading loadingInner'>
                    <DotLoading />
                </div>}
                {messages.map((message, index) => {
                    let templatecomp = null;
                    if (message.msgtype === 'template') {
                        templatecomp = JSON.parse(message?.template)?.components;
                    }
                    return (<div key={index} className={`message ${message.is_message === 'to' ? 'my-message' : 'other-message'}`} style={{ marginBottom: '10px' }}>
                        <div className='mainboddiv'>
                            {message.msgtype === 'text' ? (
                                <div className="message-text">
                                    {(() => {
                                        try {
                                            const parsedMessage = JSON.parse(message.message);
                                            if (parsedMessage.body) {
                                                return parsedMessage.body.toString();
                                            } else {
                                                return parsedMessage.toString();
                                            }
                                        } catch (e) {
                                            return message.message.toString();
                                        }
                                    })()}
                                </div>
                            ) : message.msgtype === 'interactive' ? (
                                <div className='message-intractive'>
                                    {message?.media && message.media !== '' ? (
                                        (() => {
                                            try {
                                                const parsedMessage = JSON.parse(message.media);
                                                if (parsedMessage[parsedMessage.type].title) {
                                                    return parsedMessage[parsedMessage.type].title;
                                                } else if (parsedMessage[parsedMessage.type].body) {
                                                    return parsedMessage[parsedMessage.type].body;
                                                }
                                            } catch (e) {
                                                const parsedMessage = JSON.parse(message.media);
                                                if (parsedMessage.type === 'button') {
                                                    return (
                                                        <>
                                                            <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                            <div className='prvbodytext'><div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></div></div>
                                                            <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                            {parsedMessage.action.buttons.map((button, index) => (
                                                                <div className='quickreply' key={index}>
                                                                    <a href="javascript:void(0)" rel="noreferrer">{button.reply.title}</a>
                                                                </div>
                                                            ))}
                                                        </>
                                                    );
                                                } else if (parsedMessage.type === 'list') {
                                                    return (
                                                        <>
                                                            <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                            <div className='prvbodytext'><div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></div></div>
                                                            <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                            {parsedMessage.action.sections.map((section, index) => (
                                                                <div className='list-section' key={index}>
                                                                    <div className='section-title'>{section.title}</div>
                                                                    {section.rows.map((row, idx) => (
                                                                        <div className='list-row' key={idx}>
                                                                            <div className='row-title'>{row.title}</div>
                                                                            <div className='row-description'>{row.description}</div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                            <div className='moreBtn'>{parsedMessage.action.button}</div>
                                                        </>
                                                    );
                                                } else if (parsedMessage.type === 'nfm_reply') {
                                                    return (
                                                        <>
                                                            <div>
                                                                {parsedMessage?.type}
                                                                {/* {parsedMessage?.nfm_reply?.body} */}
                                                            </div>
                                                        </>
                                                    );
                                                } else {
                                                    return parsedMessage?.type;
                                                }
                                            }
                                        })()
                                    ) : (
                                        (() => {
                                            const parsedMessage = JSON.parse(message.message);
                                            if (parsedMessage.type === 'order_status') {
                                                return <div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text.toString()) }}></div>;
                                            } else if (parsedMessage.type === 'order_details') {
                                                return <div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text.toString()) }}></div>;
                                            } else if (parsedMessage.type === 'button') {
                                                return (
                                                    <>
                                                        <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                        <div className='prvbodytext'><div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></div></div>
                                                        <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                        {parsedMessage.action.buttons.map((button, index) => (
                                                            <div className='quickreply' key={index}>
                                                                <a href="javascript:void(0)" rel="noreferrer">{button.reply.title}</a>
                                                            </div>
                                                        ))}
                                                    </>
                                                );
                                            } else if (parsedMessage.type === 'list') {
                                                return (
                                                    <>
                                                        <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                        <div className='prvbodytext'><div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></div></div>
                                                        <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                        {parsedMessage.action.sections.map((section, index) => (
                                                            <div className='list-section' key={index}>
                                                                <div className='section-title'>{section.title}</div>
                                                                {section.rows.map((row, idx) => (
                                                                    <div className='list-row' key={idx}>
                                                                        <div className='row-title'>{row.title}</div>
                                                                        <div className='row-description'>{row.description}</div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ))}
                                                        <div className='moreBtn'>{parsedMessage.action.button}</div>
                                                    </>
                                                );
                                            } else if (parsedMessage.type === 'request_welcome') {
                                                return <div className='messageArea'>
                                                    {JSON.parse(message?.media)?.message !== '' ? JSON.parse(message?.media)?.message : 'Request Welcome'}
                                                </div>;
                                            } else if (parsedMessage.type === 'address_message') {
                                                return (
                                                    <>
                                                        <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                        <div className='prvbodytext'><div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></div></div>
                                                        <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                        <hr className='buttonhr' />
                                                        <div className='moreBtn'>Provide address</div>
                                                    </>
                                                )
                                            } else if (parsedMessage.type === 'nfm_reply') {
                                                return (
                                                    <>
                                                        <div>
                                                            {parsedMessage?.nfm_reply?.body}
                                                        </div>
                                                    </>
                                                );
                                            } else {
                                                return '';
                                            }
                                        })()
                                    )}
                                </div>
                            ) : message.msgtype === 'request_welcome' ? (
                                <div className='messageArea'>
                                    {JSON.parse(message?.media)?.message !== '' ? JSON.parse(message?.media)?.message : 'Request Welcome'}
                                </div>
                            ) : message.msgtype === 'template' ? (
                                <div className="message-text">
                                    {templatecomp && templatecomp.length > 0 ? (
                                        templatecomp.map((template) => (
                                            <React.Fragment>
                                                {template.type === "HEADER" && (
                                                    template.format === 'TEXT' ? (
                                                        <p className='headeretxt'>{template.text}</p>
                                                    ) : template.format === 'IMAGE' ? (
                                                        <div className='previewContent' onClick={() => openModal(template.example.header_handle[0])}><img alt="Header" src={template.example.header_handle[0]} style={{ width: '100%', borderRadius: 8 }} /></div>
                                                    ) : template.format === 'VIDEO' ? (
                                                        <div className='previewContent' onClick={() => openVideoModal(template.example.header_handle[0])}><video src={template.example.header_handle[0]} style={{ width: '100%', borderRadius: 8 }} /></div>
                                                    ) : template.format === 'DOCUMENT' ? (
                                                        <div className='previewContent'><embed src={template.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px', borderRadius: 8 }} /></div>
                                                    ) : (
                                                        <p>Unsupported format</p>
                                                    )
                                                )}
                                                {template.type === "BODY" && (
                                                    <div className='prvbodytext' dangerouslySetInnerHTML={{ __html: handleReplaceText(template.text) }}></div>
                                                )}
                                                {template.type === "FOOTER" && (
                                                    <div className='footertext'>{template.text}</div>
                                                )}{template.type === "BUTTONS" && (
                                                    <React.Fragment>
                                                        <div style={{ borderTop: '1px solid #d6d6d6' }}></div>
                                                        {template.buttons.map((button) => (
                                                            <div key={button.id} className="templatebuttons">
                                                                {
                                                                    button.type === 'QUICK_REPLY' ? (
                                                                        <>
                                                                            <div className='quickreply'>
                                                                                <a target="_blank" href="javascript:void(0)" rel="noreferrer">
                                                                                    <MdOutlineReply />&nbsp;{button.text}
                                                                                </a>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                {
                                                                    button.type === 'URL' ? (
                                                                        <>
                                                                            <div className='urlbtn'>
                                                                                <a target="_blank" href={button.url} rel="noreferrer">
                                                                                    <LinkOutlined />&nbsp;
                                                                                    {button.text}</a>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                {
                                                                    button.type === 'PHONE_NUMBER' ? (
                                                                        <>
                                                                            <div className='phonebtn'>
                                                                                <a target="_blank" href={`tel:${button.phone_number}`} rel="noreferrer">
                                                                                    <PhoneOutlined />&nbsp;
                                                                                    {button.text}</a>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                            </div>
                                                        ))}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <React.Fragment></React.Fragment>
                                    )}
                                </div>
                            ) : message.msgtype === 'image' ? (
                                message.is_message === 'to' ? (
                                    <React.Fragment>
                                        <div className='messagePreviewImages' onClick={() => openModal(message.message)}>
                                            <img
                                                alt="Preview"
                                                style={{ width: '100%' }}
                                                src={message.message}
                                                onError={() => console.error(`Failed to load image: ${message.message}`)}
                                            />
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    message.is_message === 'from' && (
                                        <React.Fragment>
                                            {!message.image ? (
                                                (message.media ? (
                                                    <div className='messagePreviewImages' key={JSON.parse(message.media).id} onClick={() => openModal(`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}>
                                                        <img
                                                            alt="Preview"
                                                            style={{ width: '100%' }}
                                                            src={`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`}
                                                            onError={() => renderReceivedImage(message.mainid, JSON.parse(message.media).id)}
                                                        />
                                                    </div>
                                                ) : (<div className='messagePreviewImages' key={message.message}>
                                                    <div>
                                                        <Spin indicator={<LoadingOutlined spin />} />
                                                    </div>
                                                </div>))
                                            ) : (
                                                <div className='messagePreviewImages' key={message.image.id} onClick={() => openModal(message.image.url)}>
                                                    <img
                                                        alt="Preview"
                                                        style={{ width: '100%' }}
                                                        src={message.image.url}
                                                        onError={() => renderReceivedImage(message.mainid, message.image.id)}
                                                    />
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))
                            ) : message.msgtype === 'video' ? (
                                message.is_message === 'to' ? (
                                    <React.Fragment>
                                        <div className='messagePreviewVideos' onClick={() => openVideoModal(message.message)}>
                                            <video
                                                src={message.message}

                                                style={{ width: '100%' }}
                                                onError={(e) => renderReceivedVideo(message.message, message.message)}
                                            />
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    message.is_message === 'from' && (
                                        <React.Fragment>
                                            {!message.video ? (
                                                (message.media ? (
                                                    <div className='messagePreviewVideos' onClick={() => openVideoModal(`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)} key={JSON.parse(message.media).id}>
                                                        <video
                                                            src={`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`}

                                                            style={{ width: '100%' }}
                                                            onError={(e) => renderReceivedVideo(message.mainid, JSON.parse(message.media).id)}
                                                        />
                                                    </div>
                                                ) : (<div className='messagePreviewVideos' key={message.message}>
                                                    <div>
                                                        <Spin indicator={<LoadingOutlined spin />} />
                                                    </div>
                                                </div>))
                                            ) : (
                                                <div className='messagePreviewVideos' onClick={() => openVideoModal(message.video.url)} key={message.video.id}>
                                                    <video
                                                        src={message.video.url}

                                                        style={{ width: '100%' }} onError={(e) => renderReceivedVideo(message.mainid, message.video.id)}
                                                    />
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))
                            ) : message.msgtype === 'audio' ? (
                                message.is_message === 'to' ? (
                                    <React.Fragment>
                                        <div>
                                            <audio controls preload="auto" onError={(e) => renderReceivedAudio(message.audio, message.audio)}>
                                                <source src={message.message} type={getMimeType(message.message)} />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </div>
                                    </React.Fragment>
                                ) : (message.is_message === 'from' && (
                                    <React.Fragment>
                                        {!message.audio ? (
                                            (message.media ? (
                                                <div className='messagePreviewVideos'>
                                                    <audio controls preload="auto" onError={(e) => renderReceivedAudio(message.mainid, JSON.parse(message.media).id)}>
                                                        <source src={`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`} type={JSON.parse(message.media).mime_type} />
                                                        Your browser does not support the audio element.
                                                    </audio>
                                                </div>
                                            ) : (<div className='messagePreviewVideos' key={message.message}>
                                                <div>
                                                    <Spin indicator={<LoadingOutlined spin />} />
                                                </div>
                                            </div>))
                                        ) : (
                                            <div className='messagePreviewVideos' key={message.audio.id}>
                                                {message.audio.url && (
                                                    <ReactAudioPlayer
                                                    src={message.audio.url}
                                                    onError={(e) => renderReceivedAudio(message.mainid, message.audio.id)}
                                                    controls
                                                  />
                                                    // <audio controls preload="auto" onError={(e) => renderReceivedAudio(message.mainid, message.audio.id)}>
                                                    //     <source src={message.audio.url} type={getMimeType(message.audio.url)} />
                                                    // </audio>
                                                )}
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))
                            ) : message.msgtype === 'contacts' ? (
                                message.is_message === 'to' ? (
                                    <React.Fragment>
                                        <div>
                                            <audio controls onError={(e) => renderReceivedAudio(message.audio, message.audio)}>
                                                <source src={message.message} type="audio/ogg" />
                                                <source src={message.message} type="audio/mpeg" />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    message.is_message === 'from' && (
                                        <React.Fragment>
                                            {!message?.contacts ? (
                                                (message.media && (
                                                    <div key={JSON.parse(message.media)?.id}>
                                                        {console.log(JSON.parse(JSON.parse(message.media)?.callback)?.entry?.[0]?.changes?.[0]?.value?.messages?.[0]?.contacts)}
                                                        <List
                                                            grid={{ gutter: 16, column: 1 }}
                                                            dataSource={JSON.parse(JSON.parse(message.media)?.callback)?.entry?.[0]?.changes?.[0]?.value?.messages?.[0]?.contacts}
                                                            renderItem={(contact) => (
                                                                <List.Item>
                                                                    <Card style={{ padding: 10 }}>
                                                                        <p><strong>{contact?.name?.formatted_name}</strong></p>
                                                                        {contact?.phones.map((phone) => (
                                                                            <p><a href={`tel:${phone?.phone}`}>{phone?.phone}</a></p>
                                                                        ))}
                                                                    </Card>
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </div>
                                                ))
                                            ) : (
                                                <div key={message.audio.id}>
                                                    <audio controls onError={(e) => renderReceivedAudio(message.mainid, message.audio.id)}>
                                                        <source src={message.audio.url} type="audio/ogg" />
                                                        <source src={message.audio.url} type="audio/mpeg" />
                                                        Your browser does not support the audio element.
                                                    </audio>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))

                            ) : message.msgtype === 'document' ? (
                                message.is_message === 'to' ? (
                                    <React.Fragment>
                                        <div className='messagePreviewImages'>
                                            <a href={message.message} target='_blank'>
                                                {getFileExtension(message.message) === 'pdf' ? (
                                                    <img
                                                        alt="Preview"
                                                        style={{ width: '100%' }}
                                                        src={pdfIcon}
                                                        onError={() => console.error(`Failed to load image: ${message.message}`)}
                                                    />
                                                ) : getFileExtension(message.message) === 'txt' ? (
                                                    <img
                                                        alt="Preview"
                                                        style={{ width: '100%' }}
                                                        src={textIcon}
                                                        onError={() => console.error(`Failed to load image: ${message.message}`)}
                                                    />
                                                ) : getFileExtension(message.message) === 'doc' || getFileExtension(message.message) === 'docx' ? (
                                                    <img
                                                        alt="Preview"
                                                        style={{ width: '100%' }}
                                                        src={wordIcon}
                                                        onError={() => console.error(`Failed to load image: ${message.message}`)}
                                                    />
                                                ) : getFileExtension(message.message) === 'ppt' || getFileExtension(message.message) === 'pptx' ? (
                                                    <img
                                                        alt="Preview"
                                                        style={{ width: '100%' }}
                                                        src={pptIcon}
                                                        onError={() => console.error(`Failed to load image: ${message.message}`)}
                                                    />
                                                ) : getFileExtension(message.message) === 'xlsx' || getFileExtension(message.message) === 'xls' || getFileExtension(message.message) === 'csv' ? (
                                                    <img
                                                        alt="Preview"
                                                        style={{ width: '100%' }}
                                                        src={excelIcon}
                                                        onError={() => console.error(`Failed to load image: ${message.message}`)}
                                                    />
                                                ) : (
                                                    <img
                                                        alt="Preview"
                                                        style={{ width: '100%' }}
                                                        src={fileIcon}
                                                        onError={() => console.error(`Failed to load image: ${message.message}`)}
                                                    />
                                                )}
                                            </a>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    message.is_message === 'from' && (
                                        <React.Fragment>
                                            {!message.document ? (
                                                (message.media ? (
                                                    <div className='messagePreviewImages' key={JSON.parse(message.media).id}>
                                                        <a href={`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`} target='_blank'>
                                                            {getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'pdf' ? (
                                                                <img
                                                                    alt="Preview"
                                                                    style={{ width: '100%' }}
                                                                    src={pdfIcon}
                                                                    onError={() => console.error(`Failed to load image: ${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}
                                                                />
                                                            ) : getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'txt' ? (
                                                                <img
                                                                    alt="Preview"
                                                                    style={{ width: '100%' }}
                                                                    src={textIcon}
                                                                    onError={() => console.error(`Failed to load image: ${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}
                                                                />
                                                            ) : getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'doc' || getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'docx' ? (
                                                                <img
                                                                    alt="Preview"
                                                                    style={{ width: '100%' }}
                                                                    src={wordIcon}
                                                                    onError={() => console.error(`Failed to load image: ${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}
                                                                />
                                                            ) : getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'ppt' || getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'pptx' ? (
                                                                <img
                                                                    alt="Preview"
                                                                    style={{ width: '100%' }}
                                                                    src={pptIcon}
                                                                    onError={() => console.error(`Failed to load image: ${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}
                                                                />
                                                            ) : getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'xlsx' || getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'xls' || getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'csv' ? (
                                                                <img
                                                                    alt="Preview"
                                                                    style={{ width: '100%' }}
                                                                    src={excelIcon}
                                                                    onError={() => console.error(`Failed to load image: ${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}
                                                                />
                                                            ) : (
                                                                <img
                                                                    alt="Preview"
                                                                    style={{ width: '100%' }}
                                                                    src={fileIcon}
                                                                    onError={() => console.error(`Failed to load image: ${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}
                                                                />
                                                            )}
                                                        </a>
                                                        <img
                                                            src={`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`}
                                                            alt="Media"
                                                            style={{ width: '100%', height: 'auto', display: 'none' }}
                                                            onError={() => {
                                                                renderReceivedDocument(message.mainid, JSON.parse(message.media).id);
                                                            }}
                                                        />
                                                    </div>
                                                ) : (<div className='messagePreviewImages' key={message.message} onClick={() => openModal()}>
                                                    <div>
                                                        <Spin indicator={<LoadingOutlined spin />} />
                                                    </div>
                                                </div>))
                                            ) : (
                                                <div className='messagePreviewImages' key={message.document.id}>
                                                    <a href={message.document.url} target='_blank'>
                                                        {getFileExtension(message.document.url) === 'pdf' ? (
                                                            <img
                                                                alt="Preview"
                                                                style={{ width: '100%' }}
                                                                src={pdfIcon}
                                                                onError={() => console.error(`Failed to load image: ${message.document.url}`)}
                                                            />
                                                        ) : getFileExtension(message.document.url) === 'txt' ? (
                                                            <img
                                                                alt="Preview"
                                                                style={{ width: '100%' }}
                                                                src={textIcon}
                                                                onError={() => console.error(`Failed to load image: ${message.document.url}`)}
                                                            />
                                                        ) : getFileExtension(message.document.url) === 'doc' || getFileExtension(message.document.url) === 'docx' ? (
                                                            <img
                                                                alt="Preview"
                                                                style={{ width: '100%' }}
                                                                src={wordIcon}
                                                                onError={() => console.error(`Failed to load image: ${message.document.url}`)}
                                                            />
                                                        ) : getFileExtension(message.document.url) === 'ppt' || getFileExtension(message.document.url) === 'pptx' ? (
                                                            <img
                                                                alt="Preview"
                                                                style={{ width: '100%' }}
                                                                src={pptIcon}
                                                                onError={() => console.error(`Failed to load image: ${message.document.url}`)}
                                                            />
                                                        ) : getFileExtension(message.document.url) === 'xlsx' || getFileExtension(message.document.url) === 'xls' || getFileExtension(message.document.url) === 'csv' ? (
                                                            <img
                                                                alt="Preview"
                                                                style={{ width: '100%' }}
                                                                src={excelIcon}
                                                                onError={() => console.error(`Failed to load image: ${message.document.url}`)}
                                                            />
                                                        ) : (
                                                            <img
                                                                alt="Preview"
                                                                style={{ width: '100%' }}
                                                                src={fileIcon}
                                                                onError={() => console.error(`Failed to load image: ${message.document.url}`)}
                                                            />
                                                        )}
                                                    </a>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))
                            ) : message.msgtype === 'order' ? (
                                message.is_message === 'from' && (
                                    <React.Fragment>
                                        {/* {message.media && ( */}
                                        <>Order</>
                                        {/* )} */}
                                    </React.Fragment>
                                )
                            ) : message.msgtype === 'payment' ? (
                                message.is_message === 'from' && (
                                    <React.Fragment>
                                        {/* {message.media && ( )} */}
                                        <>Payment</>
                                    </React.Fragment>
                                )
                            ) : (
                                message.is_message === 'from' && (
                                    <div className='messageArea'>
                                        {JSON.parse(message.media)['receivedMessage.type']?.button?.text}
                                    </div>))}
                            {/* <br className='timemessagebr' /> */}
                            <div className='message-time'>
                                {new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(message.created * 1000))}
                                <span className='recepent'>
                                    {message.is_message === 'to' && message.status === 'sending' ? (
                                        <span key={`status${message.mainid}`} className='message_sending'><ClockCircleOutlined /></span>
                                    ) : message.is_message === 'to' && message.status === 'sent' ? (
                                        <span key={`status${message.mainid}`} className='message_sent'><CheckOutlined /></span>
                                    ) : message.is_message === 'to' && message.status === 'delivered' ? (
                                        <span key={`status${message.mainid}`} className='message_delivered'><IoCheckmarkDone /></span>
                                    ) : message.is_message === 'to' && message.status === 'read' ? (
                                        <span key={`status${message.mainid}`} className='message_read'><IoCheckmarkDone /></span>
                                    ) : (
                                        ''
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    )
                })}
                <div ref={chatRef} />
            </div>
            {textAreaDisabled ? (
                <div style={{ display: 'flex', alignItems: 'center', borderTop: '1px solid #ccc', padding: '10px', position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#fff' }} onClick={HandleTemplatePreview}>
                    <div className="old-message-overlay">
                        <b>The customer has not spoken to you in the last 24 hours.</b><br />You can only send pre-approved templates.
                    </div>
                    <Button className="sendBtnOne"><div><Image src={templateIcon} className='icon' width={'2em'} height={'2em'} fit='fill' /></div></Button>
                </div>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center', borderTop: '1px solid #ccc', padding: '10px', position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#fff' }}>

                    <Button className='templatePreviewBtn' onClick={() => setVisiblePopup(true)}><PlusOutlined /> </Button>
                    <Input
                        value={newMessage}
                        onChange={(e) => setNewMessage(e)}
                        placeholder="Type a message"
                        style={{ flex: 1, marginRight: '10px' }}
                        disabled={textAreaDisabled}
                    />
                    <Button onClick={handleSend} className='sendBtn' type="primary">{loadSentMessage ? (<SpinLoading />) : (<IoSend />)} </Button>
                </div>
            )}
            {panelOpen && (
                <FloatingPanel anchors={anchors}>
                    <div className='closeFlooat' onClick={() => { setPanelOpen(false); setFloatListData(null) }}><CloseOutlined /></div>
                    {floatListData?.action?.sections.map((section, index) => (
                        <React.Fragment key={index}>
                            <div className='section-title'>{section.title}</div>
                            <List>
                                {section.rows.map((row, idx) => (
                                    <List.Item key={idx}>
                                        <div className='row-title'>{row.title}</div>
                                        <div className='row-description'>{row.description}</div>
                                    </List.Item>
                                ))}
                            </List>
                        </React.Fragment>
                    ))}
                </FloatingPanel>
            )}
            <ImageViewer
                classNames={{
                    mask: 'customize-mask',
                    body: 'customize-body',
                }}
                image={perviewImage}
                visible={visibleViewer}
                onClose={() => {
                    setVisibleViewer(false)
                }}
            />
            <CenterPopup
                visible={visibleVideoViewer}
                onMaskClick={() => {
                    setVisibleVideoViewer(false)
                }}
            >
                <div>
                    <video src={perviewVideo} controls style={{ width: '100%', borderRadius: 8 }} />
                </div>
            </CenterPopup>
            <Popup
                className='menuPopup'
                visible={visiblePopup}
                onMaskClick={() => {
                    setVisiblePopup(false)
                }}
                onClose={() => {
                    setVisiblePopup(false)
                }}
                bodyStyle={{ height: '40vh' }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 30, marginBottom: 30 }}>
                    <Button className='imageSendBtn' onClick={handlePhotoUpload}><div><IoImagesOutline /></div><span style={{ position: 'relative', top: 5 }}>Photos</span></Button>
                    <input
                        type='file'
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept='.jpeg, .jpg, .png'
                        multiple
                        style={{ display: 'none' }}
                    />
                    <input
                        type="file"
                        ref={videoInputRef}
                        accept="video/mp4,video/3gp"
                        onChange={(e) => handleFileChangeVideo(e, 'video')}
                        style={{ display: 'none' }}
                    />
                    <input
                        type="file"
                        ref={documentInputRef}
                        multiple
                        accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx"
                        onChange={(e) => handleFileChangeDoc(e, 'document')}
                        style={{ display: 'none' }}
                    />
                    <Button className='videoSendBtn' onClick={handleVideoUpload}><div><IoVideocamOutline /></div><span style={{ position: 'relative', top: 5 }}>Videos</span></Button>
                    <Button className='documentSendBtn' onClick={handleDocumentUpload}><div><IoDocumentTextOutline /></div><span style={{ position: 'relative', top: 5 }}>Doduments</span></Button>
                    <Button className='templateListBtn' onClick={HandleTemplatePreview}><div><Image src={templateIconFilled} className='icon' width={'1em'} height={'1em'} fit='fill' /></div><span style={{ position: 'relative', top: 5 }}>Templates</span></Button>
                </div>
            </Popup>

            <ImageViewer.Multi
                visible={showPreview}
                images={imageObjects}
                defaultIndex={currentIndex}
                onIndexChange={handleIndexChange}
                onClose={() => setShowPreview(true)}
                classNames={{
                    mask: 'customize-mask',
                    body: 'customize-body',
                }}
                renderFooter={renderFooter}
                ref={viewerRef}
            />

            <Popup
                position='right'
                visible={showVideoPopup}
                showCloseButton={false}
                onClose={() => {
                    setShowVideoPopup(true);
                }}
            >
                {videoFiles.map((file, index) => (
                    <div key={index} style={{ marginBottom: '16px' }}>
                        <video controls src={URL.createObjectURL(file)} style={{ width: '100%' }} />
                    </div>
                ))}
                <div className='custom-footer'>
                    <div className='thumbnailofimagespreview'>
                        {videoFiles.map((video, index) => (
                            <div className={currentVideoIndex === index && 'active'} onClick={() => handleRemoveFile(currentVideoIndex, index)}>
                                {currentVideoIndex === index && (
                                    <span color='danger' className='removeImageBtn'><IoTrashBinOutline /></span>
                                )}
                                <video src={URL.createObjectURL(video)} style={{ width: '100%' }} className='thumbnailimage' />
                            </div>
                        ))}
                    </div>
                    <Button onClick={handleUpload} className='sendBtn' color='primary'>
                        {attachmentLoader ? (<SpinLoading />) : (<IoSend />)}
                    </Button>
                    <Button onClick={handleCancleVideoPopup} color='primary' className='closePreviewBtn'>
                        <IoCloseOutline />
                    </Button>
                </div>
            </Popup>
            <Popup
                position='bottom'
                visible={visibleTemplatePopup}
                showCloseButton
                onClose={() => {
                    setVisibleTemplatePopup(false)
                }}
            >
                <div style={{ height: 'calc(100vh - 80px)', overflowY: 'scroll' }}>
                    <h4 style={{ textAlign: 'center' }}>Templates</h4>
                    <List>
                        {templatedata.map(item => (
                            <TemplateItem key={item.id} item={item} />
                        ))}
                        {loading && <div className='loading'>{Array.from({ length: 10 }).map((_, index) => <SkeletonItem key={index} />)}</div>}
                    </List>
                    {!loading && <div className='no-more'>No more data</div>}
                </div>
            </Popup>

            <Popup
                position='right'
                visible={previewTemplate}
                showCloseButton
                onClose={() => {
                    setPreviewTemplate(false);
                    setActiveTemplateItem([]);
                    setTemplatePreview([]);
                    setVisibleTemplatePopup(true);
                }}
            >
                <div style={{ height: '100vh', width: '100vw', overflowY: 'scroll' }}>
                    <h4 style={{ textAlign: 'center' }}>{activeTemplateItem.name}</h4>
                    <div className='templatemessage'>
                        <div className="message-box preview-message">
                            <p>
                                {templatePreview && templatePreview.length > 0 && (
                                    templatePreview.map((component) => (
                                        <React.Fragment key={component.type}>
                                            {component.type === "HEADER" && (
                                                component.format === 'TEXT' ? (
                                                    <p className='headeretxt'>{component.text}</p>
                                                ) : component.format === 'IMAGE' ? (
                                                    <div className='previewContent'><img alt="Header" src={component.example.header_handle[0]} style={{ height: 'auto', width: '100%' }} /></div>
                                                ) : component.format === 'VIDEO' ? (
                                                    <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                ) : component.format === 'DOCUMENT' ? (
                                                    <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                ) : (
                                                    <p>Unsupported format</p>
                                                )
                                            )}
                                            {component.type === "BODY" && (
                                                <div className='prvbodytext' dangerouslySetInnerHTML={{ __html: handleReplaceText(component.text) }}></div>
                                            )}
                                            {component.type === "FOOTER" && (
                                                <div className='footertext'>{component.text}</div>
                                            )}
                                            {component.type === "BUTTONS" && (
                                                <React.Fragment>
                                                    <hr className='buttonhr' />
                                                    {component.buttons.map((button) => (
                                                        <div key={button.id}>
                                                            {
                                                                button.type === 'QUICK_REPLY' ? (
                                                                    <>
                                                                        <div className='quickreply'>
                                                                            <a target="_blank" href="javascript:void(0)" rel="noreferrer">
                                                                                {button.text}
                                                                            </a>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            {
                                                                button.type === 'URL' ? (
                                                                    <>
                                                                        <div className='urlbtn'>
                                                                            <a target="_blank" href={button.url} rel="noreferrer">
                                                                                <LinkOutlined />
                                                                                {button.text}</a>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            {
                                                                button.type === 'PHONE_NUMBER' ? (
                                                                    <>
                                                                        <div className='phonebtn'>
                                                                            <a target="_blank" href={`tel:${button.phone_number}`} rel="noreferrer">
                                                                                <PhoneOutlined />
                                                                                {button.text}</a>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}
                                                        </div>
                                                    ))}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    ))
                                )}
                            </p>
                        </div>
                        <br />
                        <hr />
                        <div className='variableData'>
                            {templatePreview && templatePreview.length > 0 && (
                                templatePreview.map((component) => (
                                    <React.Fragment key={component.type}>
                                        {component.type === "HEADER" && (
                                            component.format === 'TEXT' && component.text.match(/{{\d+}}/g) ? (
                                                <div>
                                                    <h4>Header</h4>
                                                    {component.text.match(/{{\d+}}/g).map(element => (
                                                        <Row key={`H${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                                            <Col span={12} style={{ paddingRight: '6px' }}>
                                                                <Select
                                                                    showSearch
                                                                    placeholder="Select Predefined Options"
                                                                    optionFilterProp="children"
                                                                    onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                                                    onSearch={onVarSearch}
                                                                    filterOption={inputfilterOption}
                                                                    options={filterOption}
                                                                    style={{ width: '100%' }}
                                                                    value={selectedValues[`${component.type}-${element}`] ? selectedValues[`${component.type}-${element}`] : undefined}
                                                                />
                                                            </Col>
                                                            <Col span={12} style={{ paddingLeft: '6px' }}>
                                                                <Input
                                                                    placeholder={`Enter Text for variable ${element}`}
                                                                    prefix={element}
                                                                    value={selectedValues[`${component.type}-${element}`]}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </div>
                                            ) : component.format === 'IMAGE' ? (
                                                <Row>
                                                    <Col span={24}>
                                                        <h4>Header</h4>
                                                    </Col>
                                                    <Col span={6} style={{ paddingRight: '6px', maxHeight: '100px', overflow: 'hidden' }}>
                                                        <div className='previewContent'><img alt="Header" src={(templateImage !== '') ? (templateImage) : (component.example.header_handle[0])} style={{ height: '100px', width: 'auto' }} /></div>
                                                    </Col>
                                                    <Col span={18} style={{ paddingLeft: '6px' }}>
                                                        <Upload
                                                            accept=".jpeg,.png"
                                                            listType="picture-card"
                                                            className="avatar-uploader"
                                                            customRequest={(fileInfo) => customRequestImage()(fileInfo)}
                                                            showUploadList={false}
                                                            action="https://connectby.io:3001/api/upload-temp-media/"
                                                            beforeUpload={checkFile}
                                                            onChange={(info) => handleChange(info)}
                                                        >
                                                            {uploadButton}
                                                        </Upload>
                                                    </Col>

                                                </Row>
                                            ) : component.format === 'VIDEO' ? (
                                                <Row>
                                                    <Col span={24}>
                                                        <h4>Header</h4>
                                                    </Col>
                                                    <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                                        <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                    </Col>
                                                    <Col span={18}>
                                                        <Upload
                                                            fileList={fileList}
                                                            customRequest={customRequest}
                                                            showUploadList={false}
                                                            beforeUpload={checkFile}
                                                            accept=".mp4"
                                                            onChange={({ fileList }) => setFileList(fileList)}
                                                        >
                                                            <Button className='addMoreFile'>
                                                                <PlusOutlined />
                                                            </Button>
                                                        </Upload>
                                                    </Col>
                                                </Row>
                                            ) : component.format === 'DOCUMENT' ? (
                                                <Row>
                                                    <Col span={24}>
                                                        <h4>Header</h4>
                                                    </Col>
                                                    <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                                        <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                    </Col>
                                                    <Col span={18}>
                                                        <Upload
                                                            fileList={fileList}
                                                            customRequest={customRequest}
                                                            showUploadList={false}
                                                            beforeUpload={checkFile}
                                                            accept=".pdf"
                                                            onChange={({ fileList }) => setFileList(fileList)}
                                                        >
                                                            <Button className='addMoreFile'>
                                                                <PlusOutlined />
                                                            </Button>
                                                        </Upload>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                ''
                                            ))}
                                        {component.type === "BODY" && (
                                            <div>
                                                <h4>Body</h4>
                                                {component.text.match(/{{\d+}}/g) && component.text.match(/{{\d+}}/g).length > 0 && (
                                                    component.text.match(/{{\d+}}/g).map(element => (
                                                        <>
                                                            <Row key={`${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                                                <Col span={12} style={{ paddingRight: '6px' }}>
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Select Predefined Options"
                                                                        optionFilterProp="children"
                                                                        onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                                                        onSearch={onVarSearch}
                                                                        filterOption={inputfilterOption}
                                                                        options={filterOption}
                                                                        style={{ width: '100%' }}
                                                                        value={selectedValues[`${component.type}-${element}`] ? selectedValues[`${component.type}-${element}`] : undefined}
                                                                    />
                                                                </Col>
                                                                <Col span={12} style={{ paddingLeft: '6px' }}>
                                                                    <Input
                                                                        placeholder={`Enter Text for variable ${element}`}
                                                                        prefix={element}
                                                                        value={selectedValues[`${component.type}-${element}`]}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    ))
                                                )}
                                            </div>
                                        )}
                                    </React.Fragment>
                                )))}
                        </div>
                        <Button type="primary" shape="circle" className="sendtemplateButton" onClick={handleSendTemplate} disabled={tmpBtnLoading}>
                            {tmpBtnLoading ? (<Spin indicator={<LoadingOutlined spin />} />) : (<SendOutlined />)}
                        </Button>
                    </div>
                </div>
            </Popup>
            <Popup
                visible={sideBarVisible}
                onClose={() => {
                    setSideBarVisible(false);
                }}
                showCloseButton
                position='right'
                bodyStyle={{ width: '100vw' }}
            >
                <div style={{ height: '100vh', overflowY: 'scroll', overflowX: 'hidden' }}>
                    <List header='&nbsp;'>
                        <List.Item
                            prefix={userData?.image ? (
                                <Avatar
                                    src={customerData?.image}
                                    style={{ '--border-radius': '40px', '--size': '40px' }}
                                />
                            ) : (
                                <div style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '40px',
                                    backgroundColor: '#075E54',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '20px',
                                    color: '#fff'
                                }}>
                                    {customerData?.customer_name?.charAt(0)}
                                </div>
                            )}
                            description={customerData?.customer_email}
                            clickable
                        >
                            <strong>{customerData?.customer_name}</strong>
                        </List.Item>
                        <List.Item prefix={<StopOutline />} extra={<Switch checked={customerData?.block === '2'} loading={blockLoading} onChange={() => handleBlockStatus(customerData?.customer_id, customerData?.block === '2' ? '1' : '2')} />}>{customerData?.block === '1' ? 'Block' : 'Unblock'}</List.Item>
                    </List>
                    <div style={{ padding: '10px' }}>
                        <div><h4>Tags</h4></div>
                        <div style={{ width: '100vw' }} className='tagsDiv'>
                            {assignedTagsData.length > 0 ? (
                                assignedTagsData.map(assignedTags => (
                                    <Tag round color='#4c4c4c'><TagOutline /> {assignedTags.tag_name}</Tag>
                                ))
                            ) : (
                                <p style={{ textAlign: 'center' }}><TagOutline style={{ fontSize: 30 }} /><br /><span>No tags assigned</span></p>
                            )}
                        </div>
                        <div><h4>Notes</h4></div>
                        <div>
                            {noteViewLoader ? (
                                <Spin className='spinner' />
                            ) : (
                                <ul className='notesul'>
                                    {notes.map(note => (
                                        <li key={note.id}><div className='notesli'>{note.note}</div><div className='notesTime'>{formatDate(note.created_at)}</div></li>
                                    ))}
                                    {customerData.note !== '' && (
                                        <li key={customerData.customer_id}><div className='notesli'>{customerData.note}</div><div className='notesTime'></div></li>
                                    )}
                                </ul>
                            )}
                            {notes.length <= 0 && customerData.note === '' && (
                                <p style={{ textAlign: 'center' }}><SnippetsOutlined style={{ fontSize: 30 }} /><br /><span>No nots added</span></p>
                            )}
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    );
};

export default ChatBox;