import React, { useState, useEffect, useLayoutEffect } from 'react';
import { messaging, getToken, onMessage } from './firebase';
import { NavBar, TabBar, Space, Avatar, ConfigProvider, Image, Dialog } from 'antd-mobile';
import { message } from 'antd';
import { Container, Button } from 'react-floating-action-button';
import { Route, Routes, useNavigate, useLocation, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { AiOutlineHome, AiOutlineWhatsApp, AiOutlineTags, AiOutlineNotification, AiFillHome, AiFillTags, AiFillNotification } from "react-icons/ai";
import Home from './pages/Home';
import Template from './pages/Template';
import ChatBox from './pages/ChatBox';
import Profile from './pages/Profile';
import Campaign from './pages/Campaign';
import Contacts from './pages/Contacts';
import Automation from './pages/Automation';
import Tags from './pages/Tags';
import Team from './pages/Team';
import Payments from './pages/Payments';
import AddContact from './pages/AddContact';
import BulkAddContact from './pages/BulkAddContact';
import AddTag from './pages/AddTag';
import AddCampaign from './pages/AddCampaign';
import AddTemplate from './pages/AddTemplate';
import BlockedContacts from './pages/BlockedContacts';
import styled from "styled-components";
import AccountBox from "./components/accountBox/index";
import './style/demo2.css';
import "./styles.css";
import templateIcon from './template.svg';
import templateIconFilled from './templateFilled.svg';
import { ClipLoader } from 'react-spinners';
import enUS from 'antd-mobile/es/locales/en-US';
import { BellOutlined, PlusOutlined } from '@ant-design/icons';
const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Bottom = ({ setPageTitle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const setRouteActive = (value) => {
    navigate(value);
  };

  const tabs = [
    {
      key: '/home',
      title: 'Home',
      icon: <AiOutlineHome />,
      activeIcon: <AiFillHome />
    },
    {
      key: '/contacts',
      title: 'Chats',
      icon: <AiOutlineWhatsApp />,
      activeIcon: <AiOutlineWhatsApp />,
    },
    {
      key: '/templates',
      title: 'Templates',
      icon: <Image src={templateIcon} className='icon' width={'1em'} height={'1em'} fit='fill' />,
      activeIcon: <Image src={templateIconFilled} className='icon' width={'1em'} height={'1em'} fit='fill' />
    },
    {
      key: '/tags',
      title: 'Tags',
      icon: <AiOutlineTags />,
      activeIcon: <AiFillTags />
    },
    {
      key: '/campaigns',
      title: 'Campaigns',
      icon: <AiOutlineNotification />,
      activeIcon: <AiFillNotification />
    },
  ];
  const currentTab = tabs.find(tab => tab.key === pathname);
  useEffect(() => {
    if (currentTab) {
      setPageTitle(currentTab.title);
    }
  }, [pathname, setPageTitle, currentTab]);

  return (
    <TabBar activeKey={pathname} onChange={setRouteActive}>
      {tabs.map((item) => (
        <TabBar.Item key={item.key} icon={pathname === item.key ? item.activeIcon : item.icon} title={item.title} badge={item.badge} />
      ))}
    </TabBar>
  );
};

const MenuApp = () => {
  const [pageTitle, setPageTitle] = useState('Home');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPermission, setIsPermission] = useState('default');
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [enableDarkMode, setEnableDarkMode] = useState(false);
  const [rightPanel, setRightPanel] = useState('');
  useLayoutEffect(() => {
    const storedMode = localStorage.getItem('darkMode');
    if (storedMode) {
      setEnableDarkMode(storedMode === 'true');
    }
  }, []);



  useLayoutEffect(() => {
    document.documentElement.setAttribute(
      'data-prefers-color-scheme',
      enableDarkMode ? 'dark' : 'light'
    );
  }, [enableDarkMode]);

  useEffect(() => {
    const redirectId = localStorage.getItem('redirectId');
    if (redirectId) {
      setIsLoggedIn(true);

    } else {
      setIsLoggedIn(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const pathTitleMap = {
      '/home': 'Home',
      '/contacts': 'Chats',
      '/templates': 'Templates',
      '/tags': 'Tags',
      '/campaigns': 'Campaigns',
      '/profile': 'Profile',
      '/team': 'Team',
      '/payments': 'Payments',
      '/add_contact': 'Add Contact',
      '/bulk_add_contact': 'Bulk add Contacts',
      '/add_campaign': 'Add Campaign',
      '/add_tag': 'Add Tag',
      '/add_template': 'Add Template',
    };
    if (pathTitleMap[pathname]) {
      setPageTitle(pathTitleMap[pathname]);
    }
  }, [pathname]);

  const requestNotificationPermissionIphone = async () => {
    let permission = await Notification.requestPermission();
    localStorage.setItem('permission', permission);
    setIsPermission(permission);
    message.success(permission === 'granted' ? 'Premission granted' : permission); // 'granted', 'denied', or 'default'
  };


  useEffect(() => {
    const registerServiceWorker = async () => {
      if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.getRegistration();
        if (!registration) {
          const newRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
          console.log('Service Worker registered with scope:', newRegistration.scope);
        } else {
          console.log('Service Worker already registered:', registration.scope);
        }
      }
    };
    registerServiceWorker();
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      message.success(`(${payload.notification.title.length > 10 ? `${payload.notification.title.slice(0, 10)}...` : payload.notification.title}) ${payload.notification.body.length > 10 ? `${payload.notification.body.slice(0, 10)}...` : payload.notification.body}`);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const redirectId = localStorage.getItem('redirectId');
        const response = await fetch(`https://connectby.io:3001/api/get_user_data_phone/${redirectId}`);
        if (response.ok) {
          const userData = await response.json();
          console.log(userData);
          requestNotificationPermission(userData);
          setUserData(userData[0]);
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const requestNotificationPermission = async (userData) => {
    // c6c08xpCs5urxIktYX9-T1:APA91bGvCtuEvCoZJtWFINr4rJtPy-xZnX6X_gj6gBUu_te-EaWFGo1okqbozSiBv7GYhe5eJSb9FBX-E3iz2AKvdUwq6RwORLvBYXTOTHWt2KVQ-kCGU_I7xQxyTMzVdm_wYr3oS0bT
    console.log('requestNotificationPermission');
    try {
      const token = await getToken(messaging, { vapidKey: 'BFZ9T0-aSrrSa1WPWdHPGsSExQTaPJe_ul0r9-RXlEm_i6sIujy9lKSBX5d5G4DeWyWE3bohe22pHRep8Ko10tA' });
      if (token) {
        if (localStorage.getItem('token') !== token) {
          if (userData[0]?.user_id) {
            console.log('FCM Token:', token);
            const updateNotificationToken = async () => {
              const response = await fetch(`https://connectby.io:3001/api/update-notification`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: userData[0]?.user_id, notification_token: token }),
              });

              if (response.ok) {
                localStorage.setItem('token', token);
                console.log('Notification token updated successfully.');
              } else {
                console.error('Error updating notification token.');
              }
            };

            updateNotificationToken();
          }
        }
      } else {
        console.log('No registration token available.');
      }
    } catch (error) {
      console.error('Error getting FCM token:', error);
    }
  };

  const truncateMessage = (message) => message.length > 20 ? message.substring(0, 20) + '...' : message;
  const [isExpirePopup, setIsExpirePopup] = useState(true);

  return (
    <ConfigProvider
      locale={enUS}
      theme={{
        colorPrimary: '#075E54',
        token: {
          // Seed Token
          colorPrimary: '#075E54',
          borderRadius: 2,

          // Alias Token
          colorBgContainer: '#f6ffed',
        },
      }}
    >
      <Container className="flaotingButtonsOne" styles={{display: localStorage.getItem('permission') === 'granted' ? 'none' : 'block'}}>
        <Button onClick={requestNotificationPermissionIphone} className="floatingBtn" styles={{ backgroundColor: '#075E54', color: '#FFFFFF' }}>
          <BellOutlined style={{fontSize:30}} />
        </Button>
      </Container>
      {loading ? (
        <div className="loading-spinner">
          <ClipLoader color="#075E54" loading={loading} size={100} />
        </div>
      ) : (
        isLoggedIn ? (
          <>
            {userData?.start_date === "" ? (
              <Dialog
                visible={isExpirePopup}
                className='planexpirepopup'
                content={
                  <>
                    <p>Hey {userData?.user_name},</p>
                    <div dangerouslySetInnerHTML={{ __html: userData?.plan_expire_popup }}></div>
                    <br />
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        style={{
                          background: '#fff',
                          padding: '10px',
                          borderRadius: '5px',
                          fontSize: '15px',
                          fontWeight: 'bold',
                        }}
                        onClick={() => window.location.href = userData?.renew_link || '#'}
                      >
                        Renew Now
                      </Button>
                    </div>
                  </>
                }
                onClose={() => setIsExpirePopup(false)}
                closeOnAction
              />
            ) : userData?.end_date <= Math.floor(Date.now() / 1000) ? (
              <Dialog
                visible={isExpirePopup}
                className='planexpirepopup'
                content={
                  <>
                    <p>Hey {userData?.user_name},</p>
                    <div dangerouslySetInnerHTML={{ __html: userData?.plan_expire_popup }}></div>
                    <br />
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        style={{
                          background: '#fff',
                          padding: '10px',
                          borderRadius: '5px',
                          fontSize: '15px',
                          fontWeight: 'bold',
                        }}
                        onClick={() => window.location.href = userData?.renew_link || '#'}
                      >
                        Renew Now
                      </Button>
                    </div>
                  </>
                }
                onClose={() => setIsExpirePopup(false)}
                closeOnAction
              />
            ) : (
              <div className="app">
                <div className="top">
                  {['Home', 'Chats', 'Templates', 'Tags', 'Campaigns'].includes(pageTitle) ? (
                    <NavBar
                      mode="light"
                      back={<span style={{ fontSize: 20, fontWeight: 'bold' }}>{truncateMessage(userData?.company_name)}</span>}
                      backIcon={false}
                      right={
                        <div onClick={() => { navigate('/profile'); setPageTitle('Profile') }} style={{ fontSize: 30, position: 'relative' }}>
                          <Space>
                            {userData?.image ? (
                              <Avatar
                                src={userData?.image}
                                style={{ '--border-radius': '40px', '--size': '40px' }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  borderRadius: '40px',
                                  backgroundColor: '#075E54',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontSize: '20px',
                                  color: '#fff'
                                }}
                              >
                                {userData?.company_name?.charAt(0)}
                              </div>
                            )}
                          </Space>
                        </div>
                      }
                    >
                    </NavBar>
                  ) : (

                    <NavBar className={`my-${pathname.replace('/', '')}`} onBack={() => navigate(-1)} mode="light" right={rightPanel}>{pageTitle}</NavBar>
                  )}
                </div>
                <div className="body">
                  <Routes>
                    <Route path='/' element={<Navigate to='/home' />} />
                    <Route path='/home' element={<Home userData={userData} />} />
                    <Route path='/chats' element={<ChatBox userData={userData} setPageTitle={setPageTitle} setRightPanel={setRightPanel} />} />
                    <Route path='/templates' element={<Template userData={userData} />} />
                    <Route path='/tags' element={<Tags userData={userData} />} />
                    <Route path='/campaigns' element={<Campaign userData={userData} />} />
                    <Route path='/contacts' element={<Contacts userData={userData} />} />
                    <Route path='/automation' element={<Automation userData={userData} />} />
                    <Route path='/team' element={<Team userData={userData} />} />
                    <Route path='/payments' element={<Payments userData={userData} />} />
                    <Route path='/add_contact' element={<AddContact userData={userData} />} />
                    <Route path='/blocked' element={<BlockedContacts userData={userData} />} />

                    <Route path='/bulk_add_contact' element={<BulkAddContact userData={userData} />} />
                    <Route path='/add_tag' element={<AddTag userData={userData} />} />
                    <Route path='/add_template' element={<AddTemplate userData={userData} />} />
                    <Route path='/add_campaign' element={<AddCampaign userData={userData} />} />
                    <Route path='/profile' element={<Profile userData={userData} enableDarkMode={enableDarkMode} setEnableDarkMode={setEnableDarkMode} setIsLoggedIn={setIsLoggedIn} />} />
                  </Routes>
                </div>
                {['Home', 'Chats', 'Templates', 'Tags', 'Campaigns'].includes(pageTitle) && (
                  <div className="bottom">
                    <Bottom setPageTitle={setPageTitle} />
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <AppContainer>
            <Routes>
              <Route path='*' element={<Navigate to='/' />} />
              <Route path='/' element={<AccountBox setIsLoggedIn={setIsLoggedIn} />} />
            </Routes>
          </AppContainer>
        )
      )}
    </ConfigProvider>
  );
};
const App = () => {
  return (
    <Router initialEntries={['/home']}>
      <MenuApp />
    </Router>
  );
};

export default App;

