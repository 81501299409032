import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyC9nEqeXy7b003YBvNuMuAzQPxwuj9sL_I",
    authDomain: "connectby-a9e11.firebaseapp.com",
    projectId: "connectby-a9e11",
    storageBucket: "connectby-a9e11.appspot.com",
    messagingSenderId: "440390115547",
    appId: "1:440390115547:web:51b96c76dcc973f306ecfe",
    measurementId: "G-0031WMRBPK"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };